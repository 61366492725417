<template>
    <div class="card small-card">
        <h3><i class="fas fa-chart-simple"></i> Statistics</h3>
        <p><strong>Total Accounts:</strong> {{ formatAmount(statistics.totalAccounts) }}</p>
        <p><strong>Total Blocks:</strong> {{ formatAmount(statistics.chainLength) }}</p>
        <p><strong>Total Transactions:</strong> {{ formatAmount(statistics.totalTxns) }}</p>
    </div>
</template>

<script>
export default {
    props: {
        statistics: {
            type: Object,
            required: true
        }
    },
    setup() {
        const formatAmount = (amount) => {
            const parts = parseFloat(amount.toFixed(8)).toString().split('.');
            parts[0] = parts[0].replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.');
        };

        return { formatAmount };
    },
};
</script>

<style scoped>
/* Add your scoped statistics styles here */
</style>

