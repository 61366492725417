<template>
    <div class="pagination">
        <button @click="goToFirstPage" :disabled="currentPage === 1" class="btn">First</button>
        <button @click="goToPrevPage" :disabled="currentPage === 1" class="btn">Previous</button>
        <span id="page-numbers">Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="goToNextPage" :disabled="currentPage === totalPages" class="btn">Next</button>
        <button @click="goToLastPage" :disabled="currentPage === totalPages" class="btn">Last</button>
    </div>
</template>

<script>
export default {
    props: {
        currentPage: {
            type: Number,
            required: true,
        },
        totalPages: {
            type: Number,
            required: true,
        },
    },
    methods: {
        goToFirstPage() {
            this.$emit('update-page', 1);
        },
        goToPrevPage() {
            if (this.currentPage > 1) {
                this.$emit('update-page', this.currentPage - 1);
            }
        },
        goToNextPage() {
            if (this.currentPage < this.totalPages) {
                this.$emit('update-page', this.currentPage + 1);
            }
        },
        goToLastPage() {
            this.$emit('update-page', this.totalPages);
        },
    },
};
</script>

<style scoped>
/* Add your scoped pagination styles here */
</style>

