<template>
    <div class="search-container">
        <input
            type="text"
            v-model="searchInput"
            class="search-input"
            placeholder="Search by Block Hash, Transaction Hash, Transaction ID, Address..."
        />
        <button class="search-btn" @click="search">Search</button>
    </div>
</template>

<script>
import MginDBClient from '../plugins/mgindb';
const client = new MginDBClient();

export default {
    data() {
        return {
            searchInput: ''
        };
    },
    mounted() {
        this.updateSearchInputFromQuery();
    },
    watch: {
        searchInput(newInput) {
            if (newInput === '') {
                this.resetSearch(); // Reset if the input is emptied by the user
            }
        },
        '$route.query.search': {
            immediate: true,
            handler(newSearch) {
                if (newSearch) {
                    if (newSearch !== this.searchInput) {
                        this.searchInput = newSearch;
                        this.search();
                    }
                } else {
                    this.resetSearch(); // Reset if the search query is not present
                }
            }
        }
    },
    methods: {
        updateSearchInputFromQuery() {
            const searchData = this.$route.query.search;
            if (searchData) {
                if (searchData !== this.searchInput) {
                    this.searchInput = searchData;
                    this.search();
                }
            } else {
                this.resetSearch(); // Reset if the search query is not present
            }
        },
        async search() {
            if (this.searchInput) {
                let response;
                let responseData;
                let type;
                if (this.searchInput.startsWith('M') && this.searchInput.length === 34) {
                    type = 'address';
                    response = await client.getWallet(this.searchInput);
                } else {
                    type = 'hash';
                    response = await client.getTxn(this.searchInput);
                }
                responseData = JSON.parse(response);
                responseData.type = type;
                this.$emit('search', responseData);
            } else {
                this.resetSearch(); // Reset if the input is empty
            }
            this.scrollToTop(); // Scroll to the top when search is triggered
        },
        resetSearch() {
            this.searchInput = ''; // Clear the input field
            this.$emit('search', null); // Emit a null search to indicate reset
            this.$router.push({ query: {} }); // Reset the URL by removing the search query parameter
            this.scrollToTop(); // Scroll to the top when reset
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top
        }
    }
};
</script>

<style scoped>
/* Add your scoped search styles here */
</style>
