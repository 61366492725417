import { createRouter, createWebHistory } from 'vue-router';
import HomeView from './views/HomeView.vue';
import TransactionsView from './views/TransactionsView.vue';
import BlocksView from './views/BlocksView.vue';

const routes = [
    { path: '/', component: HomeView },
    { path: '/transactions', component: TransactionsView },
    { path: '/blocks', component: BlocksView },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

