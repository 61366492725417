<template>
    <div class="card large-card">
        <h3><i class="fas fa-coins"></i> Market</h3>
        <p><strong>Price:</strong> ${{ market.price }}</p>
        <p><strong>Market Cap:</strong> ${{ market.marketCap }}</p>
        <p><strong>Volume:</strong> ${{ market.volume }}</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            market: {
                price: '0.50',
                marketCap: '500,000',
                volume: '500,000',
            },
        };
    },
};
</script>

<style scoped>
/* Add your scoped market styles here */
</style>

