<template>
    <div class="table-wrapper">
        <table class="table">
            <thead>
                <tr>
                    <th>Timestamp</th>
                    <th>Block</th>
                    <th>Hash / Txid</th>
                    <th>Accounts</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="tx in latestTransactions" :key="tx.hash">
                    <td>
                        <time :datetime="new Date(tx.timestamp * 1000).toISOString()" class="timeago">
                            {{ timeAgo(new Date(tx.timestamp * 1000)) }}
                        </time>
                    </td>
                    <td>
                        <router-link :to="'?search=' + tx.block_hash">{{ tx.block_index >= 0 ? `#${tx.block_index}` : 'Pending' }}</router-link>
                    </td>
                    <td>
                        <router-link :to="'?search=' + tx.hash">{{ tx.hash }}</router-link><br>
                        <router-link :to="'?search=' + tx.txid">{{ tx.txid }}</router-link>
                    </td>
                    <td>
                        From: <router-link :to="'?search=' + tx.sender">{{ tx.sender }}</router-link><br>
                        To: <router-link :to="'?search=' + tx.receiver">{{ tx.receiver }}</router-link>
                    </td>
                    <td>{{ formatAmount(tx.amount / 1e8) }} {{ tx.symbol }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { onMounted, ref, watch, onBeforeUnmount } from 'vue';
import { format } from 'timeago.js';
import MginDBClient from '../plugins/mgindb';

export default {
    props: {
        currentPage: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const latestTransactions = ref([]);
        const totalPages = ref(0);
        const transactionsPerPage = 15;
        const client = new MginDBClient();
        let intervalId;
        let timeagoIntervalId;

        const fetchLatestTransactions = async (page = 1) => {
            const offset = (page - 1) * transactionsPerPage;
            try {
                const response = await client.getTxns({ orderby: 'DESC', limit: `${offset},${transactionsPerPage}` });
                const transactions = JSON.parse(response);
                latestTransactions.value = transactions;
                totalPages.value = Math.ceil(transactions.total / transactionsPerPage);
            } catch (error) {
                console.error('Failed to fetch latest transactions:', error);
            }
        };

        const fetchLatestTransactionsPeriodically = async () => {
            const offset = (props.currentPage - 1) * transactionsPerPage;
            try {
                const response = await client.getTxns({ orderby: 'DESC', limit: `${offset},${transactionsPerPage}` });
                const transactions = JSON.parse(response);

                const newTransactions = transactions.filter(tx => !latestTransactions.value.some(existingTx => existingTx.hash === tx.hash));

                if (newTransactions.length > 0) {
                    latestTransactions.value = [...newTransactions, ...latestTransactions.value].slice(0, transactionsPerPage);
                }
            } catch (error) {
                console.error('Failed to fetch latest transactions:', error);
            }
        };

        const formatAmount = (amount) => {
            const parts = parseFloat(amount.toFixed(8)).toString().split('.');
            parts[0] = parts[0].replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.');
        };

        const timeAgo = (date) => {
            return format(date);
        };

        const refreshTimeAgo = () => {
            latestTransactions.value = [...latestTransactions.value];
        };

        onMounted(() => {
            fetchLatestTransactions(props.currentPage);
            intervalId = setInterval(fetchLatestTransactionsPeriodically, 20000);
            timeagoIntervalId = setInterval(refreshTimeAgo, 3000);
        });

        watch(() => props.currentPage, (newPage) => {
            fetchLatestTransactions(newPage);
        });

        onBeforeUnmount(() => {
            clearInterval(intervalId);
            clearInterval(timeagoIntervalId);
        });

        return { latestTransactions, totalPages, fetchLatestTransactions, formatAmount, timeAgo };
    },
};
</script>

<style scoped>
/* Add your scoped latest transactions styles here */
</style>
