<template>
    <div class="card small-card">
        <h3><i class="fas fa-coins"></i> Tokenomics</h3>
        <p><strong>Symbol:</strong> {{ tokenomics.symbol }}</p>
        <p><strong>Supply:</strong> {{ formatAmount(tokenomics.supply / 1e8) }}</p>
        <p><strong>Max Supply:</strong> {{ formatAmount(tokenomics.maxSupply / 1e8) }}</p>
    </div>
</template>

<script>
export default {
    props: {
        tokenomics: {
            type: Object,
            required: true
        }
    },
    setup() {
        const formatAmount = (amount) => {
            const parts = parseFloat(amount.toFixed(8)).toString().split('.');
            parts[0] = parts[0].replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.');
        };

        return { formatAmount };
    },
};
</script>

<style scoped>
/* Add your scoped tokenomics styles here */
</style>

