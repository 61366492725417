<template>
	<div class="main-content">
		<SearchComponent @search="processSearch" />
		<div v-if="!searchData">
			<h2>Tokenomics and Statistics</h2>
			<div class="index-grid-container" id="token-stats">
				<StatisticsComponent :statistics="statistics" />
				<TokenomicsComponent :tokenomics="tokenomics" />
				<MarketComponent />
			</div>
		</div>
		<div v-if="searchData && searchData.type === 'hash'">
			<h2>Transaction Details</h2>
			<div class="index-grid-container" id="token-stats">
				<TransactionDetailsComponent :tokenomics="tokenomics" :searchData="searchData" />
			</div>
		</div>
		<div v-if="searchData && searchData.type === 'address'">
			<h2>Wallet Details</h2>
			<div class="index-grid-container" id="token-stats">
				<WalletDetailsComponent :tokenomics="tokenomics" :searchData="searchData" />
			</div>
		</div>
		<div v-if="!searchData || (searchData && searchData.type !== 'address')">
			<h2 class="section-spacing">Latest Blocks</h2>
			<LatestBlocksComponent />
			<h2 class="section-spacing">Latest Transactions</h2>
			<LatestTransactionsComponent :currentPage="currentPage" />
			<PaginationComponent :currentPage="currentPage" :totalPages="totalPages" @update-page="updatePage" />
		</div>
		<div v-if="searchData && searchData.type === 'address'">

		</div>
	</div>
</template>
<script>
	import MginDBClient from '../plugins/mgindb';
	import SearchComponent from '../components/SearchComponent.vue';
	import StatisticsComponent from '../components/StatisticsComponent.vue';
	import TokenomicsComponent from '../components/TokenomicsComponent.vue';
	import MarketComponent from '../components/MarketComponent.vue';
	import LatestBlocksComponent from '../components/LatestBlocksComponent.vue';
	import LatestTransactionsComponent from '../components/LatestTransactionsComponent.vue';
	import TransactionDetailsComponent from '../components/TransactionDetailsComponent.vue';
	import WalletDetailsComponent from '../components/WalletDetailsComponent.vue'
	import PaginationComponent from '../components/PaginationComponent.vue';
	const client = new MginDBClient();
	export default {
		components: {
			SearchComponent,
			StatisticsComponent,
			TokenomicsComponent,
			MarketComponent,
			LatestBlocksComponent,
			LatestTransactionsComponent,
			TransactionDetailsComponent,
			WalletDetailsComponent,
			PaginationComponent,
		},
		data() {
			return {
				genesisContractHash: "",
				statistics: {
					totalAccounts: 0,
					chainLength: 0,
					totalTxns: 0
				},
				tokenomics: {
					symbol: "",
					supply: 0,
					maxSupply: 0
				},
				searchData: null,
				currentPage: 1,
				totalPages: 10,
				refreshIntervalId: null
			};
		},
		methods: {
			async blockchainConf() {
				const response = await client.getBlockchainConf();
				const responseData = JSON.parse(response);
				this.genesisContractHash = responseData.genesis_contract_hash;
				this.statistics.totalAccounts = responseData.total_accounts;
				this.statistics.chainLength = responseData.chain_length;
				this.statistics.totalTxns = responseData.total_txns;
			},
			async fetchGenesisContract() {
				try {
					const payload = {
						contract_hash: this.genesisContractHash
					};
					const response = await client.getContract(JSON.stringify(payload));
					const responseData = JSON.parse(response);
					const genesisContract = responseData.contract;
					this.tokenomics.symbol = genesisContract.symbol;
					this.tokenomics.supply = genesisContract.supply;
					this.tokenomics.maxSupply = genesisContract.max_supply;
				} catch (error) {
					console.error('Failed to fetch contract:', error);
				}
			},
			updatePage(page) {
				this.currentPage = page;
			},
			processSearch(data) {
				this.searchData = data;
				console.log('search', data)
			}
		},
		async mounted() {
			// Initial calls
			await this.blockchainConf();
			await this.fetchGenesisContract();
			// Refresh every 20 seconds
			this.refreshIntervalId = setInterval(async () => {
				await this.blockchainConf();
				await this.fetchGenesisContract();
			}, 20000); // 20000 milliseconds = 20 seconds
		},
		beforeUnmount() {
			// Clear the interval when the component is unmounted
			if (this.refreshIntervalId) {
				clearInterval(this.refreshIntervalId);
			}
		}
	};
</script>
<style scoped>
	/* Add your scoped home view styles here */
</style>