<template>
    <div class="card small-card">
        <h3><i class="fas fa-circle-info"></i> General</h3>
        <p><strong>Block:</strong> {{ searchData.block_index || searchData.index }}</p>
        <p><strong>Timestamp:</strong> {{ searchData.timestamp }}</p>
        <p v-if="searchData.sender"><strong>From:</strong> {{ searchData.sender }}</p>
        <p v-if="searchData.receiver"><strong>To:</strong> {{ searchData.receiver }}</p>
        <p v-if="searchData.amount"><strong>Amount:</strong> {{ formatAmount(searchData.amount / 1e8) }} {{ searchData.symbol }}</p>
        <p v-if="searchData.index"><strong>Transactions:</strong> {{ searchData.data.length }}</p>
        <p><strong>Fee:</strong> {{ formatAmount(searchData.fee / 1e8) }} {{ tokenomics.symbol }}</p>
    </div>
    <div class="card small-card">
        <h3><i class="fas fa-check"></i> Validation</h3>
        <p v-if="searchData.action"><strong>Action:</strong> {{ searchData.action }}</p>
        <p><strong>Nonce:</strong> {{ searchData.nonce }}</p>
        <p><strong>Difficulty:</strong> {{ searchData.difficulty }}</p>
        <p><strong>Validation time:</strong> {{ searchData.validation_time.toFixed(4) }}s</p>
        <p><strong>Size:</strong> {{ searchData.size }}</p>
        <p v-if="!searchData.index"><strong>Status:</strong> {{ !searchData.block_index ? 'Pending' : 'Confirmed' }}</p>
    </div>
    <div class="card large-card">
        <h3><i class="fas fa-shield"></i> Encryption</h3>
        <p><strong>Hash:</strong> {{ searchData.hash }}</p>
        <p v-if="searchData.txid"><strong>TxId:</strong> {{ searchData.txid }}</p>
        <p><strong>Checksum:</strong> {{ searchData.checksum }}</p>
        <p v-if="searchData.contract_hash"><strong>Contract:</strong> {{ searchData.contract_hash }}</p>
        <p><textarea disabled :value="JSON.stringify(searchData)"></textarea></p>
    </div>
</template>

<script>
export default {
    props: {
        searchData: {
            type: Object,
            required: true
        },
        tokenomics: {
            type: Object,
            required: true
        }
    },
    setup() {
        const formatAmount = (amount) => {
            const parts = parseFloat(amount.toFixed(8)).toString().split('.');
            parts[0] = parts[0].replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.');
        };

        return { formatAmount };
    },
};
</script>

<style scoped>
/* Add your scoped statistics styles here */
</style>

