<template>
    <header class="header">
        <div class="header-title" @click="navigateHome">
            <img src="/logo-192x192.png" alt="Logo" class="logo">
            <h1>MginX Explorer</h1>
        </div>
        <nav class="nav" :class="{ active: navActive }">
            <router-link to="/validator_node">Validator & Node</router-link>
            <a href="https://wallet.mginx.network" target="_blank">Wallet</a>
        </nav>
        <button class="nav-toggle" @click="toggleNav" aria-label="toggle navigation">
            <span class="hamburger"></span>
            <span class="hamburger"></span>
            <span class="hamburger"></span>
        </button>
    </header>
</template>

<script>
export default {
    data() {
        return {
            navActive: false,
        };
    },
    methods: {
        toggleNav() {
            this.navActive = !this.navActive;
        },
        navigateHome() {
            this.$router.push('/');
        },
    },
};
</script>

<style scoped>
/* Add your scoped header styles here */
</style>

