class MginDBClient {
    constructor(protocol = 'wss', host = 'master.mginx.network', port = '', username = '', password = '') {
        this.uri = `${protocol}://${host}:${port}`; // WebSocket URI
        this.username = username; // Username for authentication
        this.password = password; // Password for authentication
        this.websocket = null; // WebSocket client
        this.messageQueue = []; // Queue to handle multiple messages
    }

    async connect() {
        /**
         * Connects to the MginDB server and authenticates the user.
         *
         * @throws Error If authentication fails.
         */
        this.websocket = new WebSocket(this.uri);

        // Wait for connection to open
        await new Promise((resolve, reject) => {
            this.websocket.onopen = resolve;
            this.websocket.onerror = reject;
        });

        const authData = JSON.stringify({ username: this.username, password: this.password });
        this.websocket.send(authData);

        // Await the first message which should be a welcome message
        const response = await new Promise(resolve => this.websocket.onmessage = (event) => resolve(event.data));
        if (response !== "MginDB server connected... Welcome!") {
            throw new Error("Failed to authenticate: " + response);
        }

        // Setup a global message handler to manage the message queue
        this.websocket.onmessage = (event) => {
            const resolve = this.messageQueue.shift();
            if (resolve) {
                resolve(event.data);
            }
        };
    }

    async sendCommand(command) {
        /**
         * Sends a command to the MginDB server and returns the response.
         *
         * @param {string} command The command to send.
         * @returns {Promise<string>} The server response.
         */
        if (!this.websocket || this.websocket.readyState !== WebSocket.OPEN) {
            await this.connect();
        }
        this.websocket.send(command);
        return new Promise(resolve => this.messageQueue.push(resolve));
    }

    async close() {
        /**
         * Closes the WebSocket connection to the MginDB server.
         */
        if (this.websocket) {
            this.websocket.close();
        }
    }

    async newWallet() {
        /**
         * Get a new wallet.
         *
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`NEW_WALLET`);
    }

    async getWallet(value) {
        /**
         * Get wallet data.
         *
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`GET_WALLET ${value}`);
    }

    async getBlockchainConf() {
        /**
         * Get blockchain conf.
         *
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`BLOCKCHAIN_CONF`);
    }

    async getContract(value) {
        /**
         * Get wallet data.
         *
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`GET_CONTRACT ${value}`);
    }

    async getBlocks(options) {
        /**
         * Gets blocks with specified options.
         *
         * @param {Object} options The options for getting blocks.
         * @returns {Promise<string>} The server response.
         */
        let command = 'GET_BLOCKS';

        if (options.latest !== undefined) {
            command += ` LATEST(${options.latest})`;
        }
        if (options.limit !== undefined) {
            command += ` LIMIT(${options.limit})`;
        }
        if (options.order !== undefined) {
            command += ` ORDER(${options.order})`;
        }

        return await this.sendCommand(command);
    }

    async getBlock(value) {
        /**
         * Gets block with specified value.
         *
         * @param {string} value The value to set.
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`GET_BLOCK ${value}`);
    }

    async getTxns(options) {
        /**
         * Gets txns with specified options.
         *
         * @param {Object} options The options for getting blocks.
         * @returns {Promise<string>} The server response.
         */
        let command = 'GET_TXNS';

        if (options.latest !== undefined) {
            command += ` LATEST(${options.latest})`;
        }
        if (options.limit !== undefined) {
            command += ` LIMIT(${options.limit})`;
        }
        if (options.orderby !== undefined) {
            command += ` ORDERBY(${options.orderby})`;
        }

        return await this.sendCommand(command);
    }

    async getTxn(value) {
        /**
         * Gets tx with specified value.
         *
         * @param {string} value The value to set.
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`GET_TXN ${value}`);
    }

    async set(key, value) {
        /**
         * Sets a value for a given key in the MginDB.
         *
         * @param {string} key The key to set.
         * @param {string} value The value to set.
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`SET ${key} ${value}`);
    }

    async indices(action, key = null, value = null) {
        /**
         * Manages indices in the MginDB.
         *
         * @param {string} action The action to perform (e.g., LIST, ADD).
         * @param {string|null} key The key for the action.
         * @param {string|null} value The value for the action.
         * @returns {Promise<string>} The server response.
         */
        const command = `INDICES ${action}` + (key ? ` ${key}` : '') + (value ? ` ${value}` : '');
        return await this.sendCommand(command);
    }

    async incr(key, value) {
        /**
         * Increments a key's value by a given amount in the MginDB.
         *
         * @param {string} key The key to increment.
         * @param {string} value The amount to increment by.
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`INCR ${key} ${value}`);
    }

    async decr(key, value) {
        /**
         * Decrements a key's value by a given amount in the MginDB.
         *
         * @param {string} key The key to decrement.
         * @param {string} value The amount to decrement by.
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`DECR ${key} ${value}`);
    }

    async delete(key) {
        /**
         * Deletes a key from the MginDB.
         *
         * @param {string} key The key to delete.
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`DEL ${key}`);
    }

    async query(key, queryString = null, options = null) {
        /**
         * Queries the MginDB with a given key and query string.
         *
         * @param {string} key The key to query.
         * @param {string|null} queryString The query string.
         * @param {string|null} options Additional options for the query.
         * @returns {Promise<string>} The server response.
         */
        const command = `QUERY ${key}` + (queryString ? ` ${queryString}` : '') + (options ? ` ${options}` : '');
        return await this.sendCommand(command);
    }

    async count(key) {
        /**
         * Counts the number of records for a given key in the MginDB.
         *
         * @param {string} key The key to count.
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`COUNT ${key}`);
    }

    async schedule(action, cronOrKey = null, command = null) {
        /**
         * Schedules a task in the MginDB.
         *
         * @param {string} action The action to perform (e.g., ADD, REMOVE).
         * @param {string|null} cronOrKey The cron expression or key for the action.
         * @param {string|null} command The command to schedule.
         * @returns {Promise<string>} The server response.
         */
        const cmd = `SCHEDULE ${action}` + (cronOrKey ? ` ${cronOrKey}` : '') + (command ? ` ${command}` : '');
        return await this.sendCommand(cmd);
    }

    async sub(key) {
        /**
         * Subscribes to a key in the MginDB.
         *
         * @param {string} key The key to subscribe to.
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`SUB ${key}`);
    }

    async unsub(key) {
        /**
         * Unsubscribes from a key in the MginDB.
         *
         * @param {string} key The key to unsubscribe from.
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`UNSUB ${key}`);
    }

    async uploadFile(key, value) {
        /**
         * Upload a file for a given key in the MginDB.
         *
         * @param {string} key The key to set.
         * @param {string} value The value to set.
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`UPLOAD_FILE ${key} ${value}`);
    }

    async readFile(key) {
        /**
         * Read a file for a given key in the MginDB.
         *
         * @param {string} key The key to set.
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`READ_FILE ${key}`);
    }

    async new2FA(accountName, issuerName = 'MyWallet.Health') {
        /**
         * Generates a new 2FA secret and QR code.
         *
         * @param {string} accountName The name of the account.
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`NEW_2FA ${accountName} ${issuerName}`);
    }

    async verify2FA(secret, code) {
        /**
         * Verifies a 2FA code against a secret.
         *
         * @param {string} secret The 2FA secret.
         * @param {string} code The 2FA code to verify.
         * @returns {Promise<string>} The server response.
         */
        return await this.sendCommand(`VERIFY_2FA ${secret} ${code}`);
    }
}

export default MginDBClient;
