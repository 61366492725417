<template>
    <div class="grid-container" id="latest-blocks">
        <div v-for="block in latestBlocks" :key="block.hash" class="card">
            <div class="pointer">
                <h3><i class="fas fa-cube"></i> <router-link :to="'?search=' + block.hash">#{{ block.index }}</router-link></h3>
                <p>{{ truncateData(block.hash) }}</p>
                <p><strong>Timestamp:</strong> <time :datetime="new Date(block.timestamp * 1000).toISOString()" class="timeago">{{ timeAgo(new Date(block.timestamp * 1000)) }}</time></p>
                <p><strong>Transactions:</strong> {{ block.data.length }}</p>
                <p><strong>Size:</strong> {{ block.size }}</p>
                <p><strong>Time:</strong> {{ block.validation_time.toFixed(4) }}s</p>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, onBeforeUnmount } from 'vue';
import { format } from 'timeago.js';
import MginDBClient from '../plugins/mgindb';

export default {
    setup() {
        const latestBlocks = ref([]);
        const client = new MginDBClient();
        let intervalId;
        let timeagoIntervalId;

        const fetchLatestBlocks = async () => {
            try {
                const response = await client.getBlocks({ latest: true });
                latestBlocks.value = JSON.parse(response);
            } catch (error) {
                console.error('Failed to fetch latest blocks:', error);
            }
        };

        const fetchLatestBlocksPeriodically = async () => {
            try {
                const response = await client.getBlocks({ latest: true });
                const blocks = JSON.parse(response);
                latestBlocks.value = blocks;
            } catch (error) {
                console.error('Failed to fetch latest blocks:', error);
            }
        };

        const timeAgo = (date) => {
            return format(date);
        };

        const refreshTimeAgo = () => {
            latestBlocks.value = [...latestBlocks.value];
        };

        const truncateData = (data) => {
            return `${data.slice(0, 12)}......${data.slice(-12)}`;
		};

        onMounted(() => {
            fetchLatestBlocks();
            intervalId = setInterval(fetchLatestBlocksPeriodically, 20000);
            timeagoIntervalId = setInterval(refreshTimeAgo, 3000);
        });

        onBeforeUnmount(() => {
            clearInterval(intervalId);
            clearInterval(timeagoIntervalId);
        });

        return { latestBlocks, timeAgo, truncateData };
    },
};
</script>

<style scoped>
/* Add your scoped latest blocks styles here */
</style>
