<template>
    <div class="card small-card">
        <h3><i class="fas fa-chart-simple"></i> Balance</h3>
        <p><strong>Available:</strong> {{ formatAmount(searchData.balances?.MGX.balance / 1e8) }} {{ tokenomics.symbol }}</p>
        <p><strong>Pending:</strong> {{ formatAmount(searchData.balances?.MGX.balance_pending / 1e8) }} {{ tokenomics.symbol }}</p>
    </div>
</template>

<script>
export default {
    props: {
        searchData: {
            type: Object,
            required: true
        },
        tokenomics: {
            type: Object,
            required: true
        }
    },
    setup() {
        const formatAmount = (amount) => {
            const parts = parseFloat(amount.toFixed(8)).toString().split('.');
            parts[0] = parts[0].replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.');
        };

        return { formatAmount };
    },
};
</script>

<style scoped>
/* Add your scoped statistics styles here */
</style>

